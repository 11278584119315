<template>
  <div class="container">
    <content-title :nav="nav"> </content-title>
    <!-- <el-button @click="addCourseOpen('场馆')">新增场馆预约</el-button> -->
    <el-button @click="addCourseOpen('预约')">预约排课</el-button>
    <el-button @click="addCourseOpen('固定')">固定排课</el-button>
    <el-button @click="courseAppointment">课包第一节排课</el-button>
    <!-- 新增场馆预约课表 -->
    <el-dialog
      :visible.sync="addPlaceScheduleShow"
      title="新增场馆预约课表"
      width="50%"
      :close-on-click-modal="false"
    >
        <div class="">
          <el-form :model="serviceForm">
            <el-form-item label="选择机构">
              <el-select
                ref="agentSelect"
                @hook:mounted="cancalReadOnly"
                @visible-change="cancalReadOnly"
                v-model="serviceForm.mechanism_id"
                remote
                :remote-method="getMechanism"
                filterable
              >
                <el-option
                  v-for="item in mechanism_list"
                  :key="item.id"
                  :value="item.id"
                  :label="item.mechanism_name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择场馆">
              <el-select
                ref="agentSelect"
                @hook:mounted="cancalReadOnly"
                @visible-change="cancalReadOnly"
                :disabled="!serviceForm.mechanism_id"
                v-model="venue_id"
                remote
                :remote-method="getPlace"
                filterable
                @focus="getPlace"
              >
                <el-option
                  v-for="item in place_list"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择服务">
              <el-select
                ref="agentSelect"
                @hook:mounted="cancalReadOnly"
                @visible-change="cancalReadOnly"
                :disabled="!venue_id"
                v-model="serviceForm.server_id"
                remote
                :remote-method="getService"
                clearable
                @focus="getService"
                @change="changeService"
              >
                <el-option
                  v-for="item in service_list"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择设备">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
              <div style="margin: 15px 0"></div>
              <el-checkbox-group
                v-model="checkedDevice"
                @change="handleCheckedCitiesChange"
              >
                <el-checkbox
                  v-for="dev in deviceList"
                  :label="dev"
                  :key="dev.id"
                  >{{ dev.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="设备可预约次数">
              <el-input
                placeholder="每个时间段设备的可预约数"
                v-model="inventory"
              ></el-input>
              <p>
                即每个时间段场次的出售次数，如：羽毛球场每个时间段可约次数为1，游泳场馆为10等
              </p>
            </el-form-item>
            <el-form-item label="选择日期">
              请选择你要排课的日期，{{
                "已选择" + serviceTimeList.length + "天"
              }}

              <el-popover width="600" trigger="click">
                <div >
                  <venue-time-change
                    ref="dayChange"
                    @change="serviceTimeChnage"
                  ></venue-time-change>
                </div>
                <el-button slot="reference">选择日期</el-button>
              </el-popover>
            </el-form-item>
          </el-form>
        </div>

      <div class="flex-col">
        <div class="flex al-c">
          若已经排课，请点击查看课表后再排课。
          <el-button type="primary" @click="getPlaceAppointmentInfo()"
            >查看课表</el-button
          >
          <el-button @click="clearPlacetable">清空</el-button>
        </div>
        <calendar @postSchedule="getSchedule" :list="venueList" />
      </div>

      <span slot="footer">
        <el-button :loading="placeLoading" @click="addPlaceSchedule()" type="primary">确认</el-button>
        <el-button @click="handleClose">取消</el-button>
      </span>
    </el-dialog>
    <!-- 预约排课 -->
    <el-dialog
      :width="ks ? '90%' : ''"
      :visible="addCourseVisible"
      title="预约排课"
      :before-close="handleClose"
    >
      <el-form :model="appointmentForm" ref="aform" label-width="auto">
        <el-form-item label="课程类型">
          <el-radio-group v-model="course_type">
            <el-radio label="mechanism_offline">海马课包</el-radio>
            <el-radio label="need">需求课程</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="course_type == 'mechanism_offline'">
          <el-form-item label="选择机构" required>
            <el-select
              ref="agentSelect"
              @hook:mounted="cancalReadOnly"
              @visible-change="cancalReadOnly"
              v-model="appointmentForm.mechanism_id"
              placeholder="请输入课程名称"
              prefix-icon="el-icon-search"
              filterable
              remote
              :remote-method="getMechanism"
              @change="changeMechanism"
            >
              <el-option
                v-for="(item, index) in mechanism_list"
                :key="index"
                :label="item.mechanism_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择课程" required key="offline">
            <el-select
              ref="agentSelect"
              @hook:mounted="cancalReadOnly"
              @visible-change="cancalReadOnly"
              remote
              :remote-method="getCourse"
              @focus="getCourse"
              v-model="course"
              value-key="id"
              filterable
              placeholder="请输入班级名称/课程名称"
              prefix-icon="el-icon-search"
              @change="changeCourse"
            >
              <el-option
                v-for="(item, index) in course_list"
                :key="index"
                :label="item.title"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择教练">
            <el-select
              ref="agentSelect"
              @hook:mounted="cancalReadOnly"
              @visible-change="cancalReadOnly"
              v-model="appointmentForm.master_id"
              remote
              :remote-method="getTeaList"
              placeholder="请选择教练"
              filterable
              @focus.once="getTeaList"
              prefix-icon="el-icon-search"
            >
              <el-option
                v-for="(item, index) in teaList"
                :key="index"
                :value="item.id"
                :label="item.full_name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="course_type == 'need'">
          <el-form-item label="上课教练">
            <coach-select :coachid.sync="changeCoach_id"></coach-select>
          </el-form-item>
          <el-form-item label="上课用户">
            <user-select :userid.sync="changeUser_id"></user-select>
          </el-form-item>
          <el-form-item label="选择课程" key="need" required>
            <el-select
              ref="agentSelect"
              @hook:mounted="cancalReadOnly"
              @visible-change="cancalReadOnly"
              remote
              :remote-method="getCourse"
              @focus="getCourse"
              v-model="course"
              value-key="id"
              filterable
              placeholder="请输入课程名称"
              prefix-icon="el-icon-search"
              @change="changeCourse"
            >
              <el-option
                v-for="(item, index) in course_list"
                :key="index"
                :label="item.title"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="月份" required>
          <el-date-picker
            v-model="value1"
            @change="selectMonth"
            type="month"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="第几周" required>
          <el-select
            ref="agentSelect"
            @hook:mounted="cancalReadOnly"
            @visible-change="cancalReadOnly"
            v-model="appointmentForm.week_num"
            :disabled="!month"
          >
            <el-option
              :label="'第' + i + '周'"
              :value="i"
              v-for="(i, index) in Math.ceil(
                getDateCount(appointmentForm.months_num) / 7
              )"
              :key="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课节数">
          <el-input v-model="course_num"></el-input>节
        </el-form-item>
        <el-form-item label="课间休息时长">
          <el-input v-model="time"></el-input>分钟
        </el-form-item>
        <el-form-item label="课程开始时间" required>
          <el-time-select
            placeholder="起始时间"
            v-model="startTime"
            :picker-options="{
              start: '06:00',
              step: '00:05',
              end: '23:00',
            }"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="日期选择">
          <el-checkbox-group v-model="week_day" @change="currentWeek">
            <el-checkbox
              v-for="(i, index) in date_count1(
                appointmentForm.week_num,
                appointmentForm.months_num
              )"
              :key="index"
              :label="(appointmentForm.week_num - 1) * 7 + i"
            >
              {{ (appointmentForm.week_num - 1) * 7 + i }}号
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          v-if="addCourseVisible"
          @click="newAppointCourse()"
          >添 加</el-button
        >
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 固定排课 -->
    <el-dialog
      :width="ks ? '90%' : ''"
      :visible="addGudingCourse"
      :before-close="handleClose"
      title="新增固定课表"
    >
      <el-form :model="fixedForm">
        <el-form-item label="课程类型">
          <el-radio-group v-model="course_type" @input="typeChange">
            <el-radio label="mechanism_offline">海马课包</el-radio>
            <el-radio label="need">需求课程</el-radio>
          </el-radio-group>
        </el-form-item>
         <el-form-item label="排课方式">
          <el-radio-group v-model="fixed_type">
            <el-radio label="user">选择用户</el-radio>
            <el-radio label="class">选择班级</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="course_type == 'mechanism_offline'">
          <el-form-item label="选择机构">
            <mechanism-select
              :mecid.sync="fixedForm.mechanism_id"
            ></mechanism-select>
          </el-form-item>

          <el-form-item label="选择课程">
            <el-select
              ref="agentSelect"
              @hook:mounted="cancalReadOnly"
              @visible-change="cancalReadOnly"
              :disabled="!fixedForm.mechanism_id"
              v-model="course"
              remote
              :remote-method="getCourse"
              clearable
              filterable
              @focus="getCourse"
              value-key="id"
              @change="getCount"
            >
              <el-option
                v-for="item in course_list"
                :key="item.id"
                :value="item"
                :label="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择班级" v-if="fixed_type == 'class'">
            <el-select
              ref="agentSelect"
              @hook:mounted="cancalReadOnly"
              @visible-change="cancalReadOnly"
              :disabled="!fixedForm.master_set_price_id"
              v-model="change_class_id"
              remote
              :remote-method="getMechanismClass"
              placeholder="选择班级"
              filterable
              clearable
              @focus.once="getMechanismClass"
              prefix-icon="el-icon-search"
            >
              <el-option
                v-for="(item, index) in classList"
                :key="index"
                :value="item.id"
                :label="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="course_type == 'need'">
          <el-form-item label="选择教练">
            <coach-select :coachid.sync="changeCoach_id"></coach-select>
          </el-form-item>
          
          <el-form-item label="选择课程" v-if="fixed_type == 'user'">
            <el-select
              ref="agentSelect"
              @hook:mounted="cancalReadOnly"
              @visible-change="cancalReadOnly"
              key="fixn"
              v-model="course"
              remote
              :remote-method="getCourse"
              clearable
              filterable
              @focus="getCourse"
              value-key="id"
              @change="getCount"
            >
              <el-option
                v-for="item in course_list"
                :key="item.id"
                :value="item"
                :label="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>

           <el-form-item label="选择班级" v-if="fixed_type == 'class'">
            <el-select
              ref="agentSelect"
              @hook:mounted="cancalReadOnly"
              @visible-change="cancalReadOnly"
              v-model="change_class_id"
              remote
              :remote-method="getMechanismClass"
              placeholder="选择班级"
              filterable
              clearable
              @focus.once="getMechanismClass"
              prefix-icon="el-icon-search"
            >
              <el-option
                v-for="(item, index) in classList"
                :key="index"
                :value="item.id"
                :label="item.name"
              >
              <div>
                <span>{{ item.name }}</span>
                <span>-{{item.course_name || ''}}</span>
              </div>
              </el-option>
            </el-select>
          </el-form-item>

        </div>
        <el-form-item label="选择用户" v-if="fixed_type == 'user'">
          <div style="width: 80%; float: left">
            <el-table
              :data="user_list"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="55"
                prop="id"
              ></el-table-column>
              <el-table-column label="ID" prop="id"></el-table-column>
              <el-table-column
                label="用户昵称"
                prop="map.userinfo.nick_name"
              ></el-table-column>
              <el-table-column
                label="用户手机"
                prop="map.userinfo.mobile"
              ></el-table-column>
            </el-table>
            <el-pagination
              :total="user_total"
              :current-page="currentFixedPage"
              @current-change="changeCurrentPage"
            >
            </el-pagination>
          </div>
        </el-form-item>
        <el-form-item label="上课时间段">
          <el-time-select
            placeholder="起始时间"
            v-model="startTime"
            value-format="hh:mm:ss"
            :picker-options="{ start: '07:00', step: '00:05', end: '23:00' }"
          >
          </el-time-select>
          -
          <el-time-select
            placeholder="结束时间"
            v-model="endTime"
            :picker-options="{
              start: '07:00',
              step: '00:05',
              end: '23:00',
              minTime: startTime,
            }"
            value-format="hh:mm:ss"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="排课方式">
          <el-radio v-model="fixedForm.type" label="week">星期</el-radio>
          <el-radio v-model="fixedForm.type" label="calendar">日历</el-radio>
        </el-form-item>
        <!-- <el-form-item label="是否重复">
          <el-checkbox v-model="fixedForm.is_repeat"></el-checkbox>
        </el-form-item> -->
        <el-form-item label="选择日期" v-if="fixedForm.type == 'calendar'">
          <calendar2 @selected="getSelectedDate"></calendar2>
        </el-form-item>

        <el-form-item label="选择星期" v-if="fixedForm.type == 'week'">
          <el-checkbox-group v-model="week_day" @change="gudingCurrentWeek">
            <el-checkbox v-for="(i, index) in weeks" :key="index" :label="i">
              {{ i }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="日期范围">
          <!-- <el-date-picker
            type="daterange"
            v-model="value2"
            :picker-options="pickerOptions"
            @change="getDateRange"
          >
          </el-date-picker> -->
          <el-date-picker
            v-model="value2"
            align="left"
            type="date"
            placeholder="开始日期"
            :picker-options="pickerOptions"
            @change="getDateRange"
          >
          </el-date-picker>
          -
          <el-date-picker
            v-model="value3"
            align="right"
            type="date"
            placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="getDateRange1"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="addNewFiexed()">确认</el-button>
        <el-button @click="handleClose">取消</el-button>
      </span>
    </el-dialog>
    <courseAppointment ref="course"></courseAppointment>
  </div>
</template>

<script>
import calendar from "../../components/calendar.vue";
import courseWeek from "../../components/courseweek.vue";
import calendar2 from "../../components/calendar2.vue";
import venueTimeChange from "./components/venueTimeChange.vue";
import courseAppointment from './components/courseAppointment.vue';

export default {
  components: { courseWeek, calendar, calendar2, venueTimeChange,courseAppointment },
  data() {
    return {
      nav: {
        firstNav: "课程管理中心",
        secondNav: "预约排课",
      },
      mechanism_list: [],
      mechanism_id: null,
      master_set_price_id: null,
      teaList: [], // 教练列表
      form: {},
      fixedForm: {
        type: "week",
        master_set_price_id:''
      },
      course_type: "mechanism_offline",
      course_num: null,
      rest_time: null,
      appointmentForm: {
        mechanism_id: "",
        months_num: new Date().getMonth() + 1,
        week_num: Math.ceil(new Date().getDate() / 7),
      },
      serviceForm: {
        months_num: new Date().getMonth() + 1,
        week_num: Math.ceil(new Date().getDate() / 7),
      },
      place_list: [],
      rules: {},
      month: new Date().getMonth(),
      weeks: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 86400000;
        },
      },
      startTime: "",
      course: "",
      temp: {},
      value1: new Date(),
      user_list: [],
      user_total: 0,
      changeUser_id: "",
      couInfo: null,
      value2: null,
      value3: null,
      time: null,
      endTime: null,
      addPlaceScheduleShow: false,
      addCourseVisible: false,
      addGudingCourse: false,
      serviceForm: {},
      months_num: new Date(),
      week_day: [],
      master_set_price_id: null,
      commodity_type: "正式课",
      service_list: [],
      currentFixedPage: 1,
      course_list: [],
      if_clear: false,
      week_num: 1,
      ks: false,
      deviceList: [],
      checkAll: false,
      checkedDevice: [],
      isIndeterminate: false,
      venueList: [],
      venue_id: "",
      serviceTimeList: [],
      inventory: 1,
      placeLoading:false,
      changeCoach_id:'',
      fixed_type:'user',
      classList:[],
      change_class_id:'',
      startTime:''  ,
      endTime:''
    };
  },
  watch: {
    mechanism_id(newV, oldV) {
      this.master_set_price_id = null;
      this.course_list = [];
    },
    commodity_type(newV, oldV) {
      if (newV && oldV != null) {
        this.serviceForm.months_num = new Date().getMonth() + 1;
        this.serviceForm.week_num = Math.ceil(new Date().getDate() / 7);
        this.serviceForm.list = [];
      }
    },
  },
  mounted() {
  
  },

  methods: {
    courseAppointment(){
        this.$refs.course.open()
    },
    getMechanismClass(){
        let url = "/user/mechanismClasses/queryManagerListPage";

  let param = {}
      if(this.course_type == 'need'){
          param = {
             pageSize: 10,
            currentPage: 1,
            status: 2,
            coach_id: this.changeCoach_id
          }
      }else{
          param =  {
            pageSize: 10,
            currentPage: 1,
            status: 2,
            master_set_price_id:this.fixedForm.master_set_price_id,
            mechanism_id:this.fixedForm.mechanism_id,
          }
      }

      this.$axios
        .get(url, {
          params:param
        })
        .then((res) => {
          let result = res.data.data.rows
          result.forEach(item=>{
            item.name = `${item.name}(${item.student_count}人)`
          })
          this.classList = result;
        })
    },
    serviceTimeChnage(v) {
      this.serviceTimeList = v;
    },
    // 查看课表
    getPlaceAppointmentInfo() {
      if (!this.serviceForm.server_id) {
        this.$message("请选择服务");
        return;
      }
      if (this.checkedDevice.length == 0) {
        this.$message("请选择设备");
        return;
      }
      let url = "/user/server/schedule/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 7,
            currentPage: 1,
            server_id: this.serviceForm.server_id,
            device_id: this.checkedDevice[0].id,
          },
        })
        .then((res) => {
          let data = res.data.data.rows || [];
          let list = [];
          let dataObj = {}
          data.forEach((item) => {
            let json = JSON.parse(item.schedule_json);
            json.forEach((c) => {
              c.week_day = item.week_day;
              dataObj[item.week_day + c.start_time] = c
            });
            // list = [...list, ...json];
          });
          console.log(dataObj);
      for(let k in dataObj){
        list.push(dataObj[k])
      }
          this.venueList = list;
        });
    },
    handleCheckAllChange(val) {
      this.checkedDevice = val ? this.deviceList : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.deviceList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.deviceList.length;
    },
    // 设备列表
    getDeviceList(id) {
      let url = "/user/venue/device/getByServerId";
      this.$axios
        .get(url, {
          params: {
            server_id: id,
          },
        })
        .then((res) => {
          this.deviceList = res.data.data;
        });
    },
    changeService(v) {
      this.getDeviceList(v);
    },
    typeChange() {
      this.fixedForm = {};
      //开始日期
      let start = (week_num - 1) * 7 + 1;
      //结束日期
      let end = start + 6;
      let week;
      let start_time;
      //循环这周的日期，判断星期是否相等
      for (let i = start; i <= end; i++) {
        week = new Date(2022, month, i).getWeekDay();
        if (week == week_day) {
          // 星期相等，就是这天
          start_time = `2022-month-${i}`;
          break;
        }
      }
    },
    courseName(id) {
      let a = this.course_list.find((item) => {
        return item.id == id;
      });
      if (a) {
        return a.title;
      } else {
        return null;
      }
    },
    getCount(v) {
      this.fixedForm.count = v.course_num;
      this.fixedForm.master_set_price_id = v.id
      if(this.fixed_type == 'class'){

      }else{
      this.getUserList();

      }
    },
    // 新增固定课表
    addNewFiexed() {
      let url = "/user/mechanismClasses/insertMechanismStudentCourses";
      let data = { ...this.fixedForm };
      data.start_times = this.startTime ? this.startTime + ":00" : null;
      data.end_times = this.endTime ? this.endTime + ":00" : null;
      data.date = data.date ? data.date.split(" ")[0] + " 23:59:59" : null;
      data.is_qualified = true;
      data.is_repeat = true;
      data.course_type = 1;
      if(this.fixed_type == 'class'){
        data.id = this.change_class_id
      }
      // 需求课程
      if (this.course_type == "need") {
        data.course_type = 2;
      }
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "success", message: res.data.data });
          this.addGudingCourse = false;
          this.fixedForm = {};
          this.fixedForm.mechanism_id = ''
          this.change_class_id = ''
          this.startTime = ''
          this.endTime = ''
          this.changeCoach_id = ''
          this.coach_id = ''
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    getCourse(query) {
      let url;
      let params;
      if (this.course_type == "need") {
        url = "/user/userCourseNeed/queryManagerListPage";
        params = {
          pageSize: 50,
          // status: 5,
          coach_id:this.changeCoach_id || null,
          currentPage: 1,
          title: typeof query == "string" ? query : null,
        };
        if (this.changeUser_id) {
          params.user_id = this.changeUser_id;
        } else {
          params.mechanism_id =
            this.appointmentForm.mechanism_id ||
            this.fixedForm.mechanism_id ||
            null;
        }
        if (this.addCourseVisible) {
          params.appointment_type = 1;
        }
        if (this.addGudingCourse) {
          params.appointment_type = 2;
        }
      } else {
        url = "/user/masterSetPrice/queryByMessage";
        params = {
          type: "mechanism_offline",
          status: 2,
          pageSize: 50,
          currentPage: 1,
          appointment_type: this.addGudingCourse
            ? "fixed_scheduling"
            : this.addCourseVisible
            ? "appointment"
            : null,
          // categories: "体育运动",
          mechanism_id:
            this.appointmentForm.mechanism_id || this.fixedForm.mechanism_id,
          title: typeof query == "string" ? query : null,
          binding_venues_id: 0,
        };
      }
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.course_list = res.data.data.rows;
        });
      // }
    },
    getUserList() {
      let url = "/user/userStudyCard/queryStudentInfo";
      this.status = "loading";
      let params = {
        pageSize: 10,
        currentPage: this.currentFixedPage,
        studycard_id: this.fixedForm.master_set_price_id,
        status: 2,
        mechanism_class_id:0,
        mechanism_id: this.fixedForm.mechanism_id || null,
      };
      // if (this.course_type == "need") {
      //   params.type = "need";
      // }else{
      //   params.type = "mechanism_offline"
      // }

      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          let arr = res.data.data.rows.filter((item) => {
            return item.course_num > 0;
          });
          this.user_list = arr;
          // this.studycard_list = [...this.studycard_list, arr]
          this.user_total = res.data.data.total;
        });
    },
    selectedCourse() {},
    // 新增预约课表
    newAppointCourse() {
      if (
        this.course_type != "need" &&
        this.appointmentForm.mechanism_id == ""
      ) {
        this.$message({ message: "请选择机构" });
        return;
      }
      if (this.course == "") {
        this.$message({ message: "请选择课程" });
        return;
      }
      if (this.startTime == "") {
        this.$message({ message: "请选择时间" });
        return;
      }
      this.currentWeek(this.week_day);
      let url = "/user/mechanismSchedule/insertCourseScheduleList";
      let data = { ...this.appointmentForm };
      data.type = "上课";
      if (this.course_type == "need") {
        url = "/user/coachSchedule/batchInsertSchedule";
        data.coach_id = this.couInfo.coach_id;
        data.mechanism_id = null;
        data.type = 1;
      }

      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "success", message: res.data.message });
          this.addCourseVisible = false;
          this.course = {};
          this.time = null;
          this.startTime = ''
           this.endTime = ''
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    changeCurrentPage(v) {
      this.currentFixedPage = v;
      this.getUserList();
    },
    changeMechanism(v) {
      this.getCourse();
    },
    changeCourse(v) {
      this.couInfo = v;
      this.appointmentForm.id = v.id;
    },
    date_count1(week_num, months_num) {
      if (week_num <= Math.floor(this.getDateCount(months_num) / 7)) {
        return 7;
      } else {
        return this.getDateCount(months_num) % 7;
      }
    },
    getWeekDay(v) {
      let week_day;
      this.serviceForm.list = [];
      v.forEach((item) => {
        let date = new Date(
          new Date().getFullYear(),
          this.serviceForm.months_num - 1,
          item
        );
        week_day = date.getDay();
        this.serviceForm.list.push({
          start: date.Format("yyyy-MM-dd") + " 00:00:00",
          end: date.Format("yyyy-MM-dd") + " 23:59:59",
          is_expensive: false,
          week_day: week_day == 0 ? 7 : week_day,
          time: 0,
        });
      });
    },
    getDateRange(v) {
      // this.fixedForm.start_date = v[0].Format("yyyy-MM-dd hh:mm:ss");
      // this.fixedForm.date = v[1].Format("yyyy-MM-dd hh:mm:ss");
      this.fixedForm.start_date = v.Format("yyyy-MM-dd hh:mm:ss");
    },
    getDateRange1(v) {
      // this.fixedForm.start_date = v[0].Format("yyyy-MM-dd hh:mm:ss");
      this.fixedForm.date = v.Format("yyyy-MM-dd hh:mm:ss");
    },
    selectMonth(e) {
      this.appointmentForm.months_num = e.getMonth() + 1;
      this.appointmentForm.week_num = 1;
    },
    gudingCurrentWeek(v) {
      this.fixedForm.weekOfDays = v.join(",");
    },
    handleSelectionChange(v) {
      let arr = [];
      v.forEach((item) => {
        arr.push(item.id);
      });
      this.fixedForm.userStudyCard_ids = arr.join(",");
    },
    getPlace(query) {
      let url = "/user/mechanism/venue/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 0,
            pageSize: 10,
            currentPage: 1,
            mechanism_id: this.serviceForm.mechanism_id,
            name: typeof query == "string" ? query : null,
          },
        })
        .then((res) => {
          this.place_list = res.data.data.rows;
        });
    },
    addPlaceSchedule() {
      if(this.serviceTimeList.length == 0){
        this.$message("请选择日期");
        return
      }
      // let url = "/user/mechanismSchedule/insertCourseScheduleList";
      let form = {};
      let url = "/user/server/schedule/batchInsert";
      if (this.checkedDevice.length > 0) {
        let newlist = [];
        let map = {};
        this.serviceForm.list.forEach((list) => {
          let l = Object.assign({}, list);
          l.is_big = list.is_big ? 1 : 0;
          l.inventory = this.inventory;
          if (l.id || l.on_open === false) {
            delete l.on_open;
            if (map[l.week_day]) {
              map[l.week_day].push(l);
            } else {
              map[l.week_day] = [l];
            }
          }
        });
        form.scheduleMap = map;
        form.server_id = this.serviceForm.server_id;
        form.device_ids = this.checkedDevice.map((item) => {
          return item.id;
        });
        form.dates = this.serviceTimeList;
      } else {
        this.$message("请选择设备");
        return;
      }
      if (!this.serviceForm.list || this.serviceForm.list.length == 0) {
        this.$message("请排课");
        return;
      }
      this.placeLoading = true
      this.$axios.post(url, form).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "success", message: "添加成功!" });
          this.addPlaceScheduleShow = false;
          this.serviceForm = {};
          this.mechanism_id = null;
          this.checkAll = false;
          this.checkedDevice = []
          this.service_list = []
          this.$refs.dayChange.clear();
          this.clearPlacetable();
          this.venue_id = ''
          this.course = ''
           this.startTime = ''
           this.endTime = ''
        } else {
          this.serviceForm = {};
          this.$message({ type: "error", message: res.data.message });
        }
        this.placeLoading = false
      }).catch(err=>{
        this.placeLoading = false
      })
    },
    getTeaList(query) {
      let url = "/user/masterInfo/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: 1,
            // source:'教练',
            categories: "教练",
            type: "教练",
            status: 2,
            mechanism_id:
              this.appointmentForm.mechanism_id || this.fixedForm.mechanism_id,
            full_name: typeof query == "string" && query ? query : null,
          },
        })
        .then((res) => {
          // console.log(res.data);
          this.teaList = res.data.data.rows;
        })
        .catch((rej) => {});
    },
    currentWeek(v) {
      let week_day;
      this.appointmentForm.list = [];
      v.forEach((item) => {
        let date = new Date(
          new Date().getFullYear(),
          this.appointmentForm.months_num - 1,
          item
        );
        week_day = date.getDay();
        let start = date.Format("yyyy-MM-dd") + " " + this.startTime + ":00";
        let end = new Date(
          new Date(start).getTime() +
            this.course_num * this.couInfo.length_of_lesson * 60 * 1000 +
            (this.course_num - 1) * this.time * 60 * 1000
        ).Format("yyyy-MM-dd hh:mm:ss");
        this.appointmentForm.list.push({
          start: start,
          end: end,
          is_expensive: false,
          week_day: week_day == 0 ? 7 : week_day,
          time: this.time || 0,
        });
      });
    },
    addCourseOpen(v) {
      this.mechanism_list = [];
      this.week_day = [];
      // this.mechanism_id = null
      this.master_set_price_id = null;
      this.course_type = "mechanism_offline";
      switch (v) {
        case "场馆":
          this.addPlaceScheduleShow = true;
          break;
        case "预约":
          this.addCourseVisible = true;
          break;
        case "固定":
          this.addGudingCourse = true;

          break;
      }
    },
    getSelectedDate(v) {
      this.fixedForm.weekOfDays = v.join(",");
    },
    getMonth(e) {
      // console.log(e);
      this.$set(this.serviceForm, "months_num", e.getMonth() + 1);
    },
    getDateCount(month) {
      if (month) {
        return new Date(new Date().getFullYear(), month, 0).getDate();
      } else {
        return null;
      }
    },
    getService(query) {
      let url = "/user/venue/server/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 1,
            name: typeof query == "string" ? query : undefined,
            pageSize: 10,
            currentPage: 1,
            mechanism_id:this.serviceForm.mechanism_id,
            type: 1,
            venue_id : this.venue_id
          },
        })
        .then((res) => {
          this.service_list = res.data.data.rows;
        });
    },
    getMechanism(query) {
      let url = "/user/mastermechanism/queryByMessage";
      if (typeof query == "string") {
        this.$axios
          .get(url, {
            params: {
              type: "teach_paypal",
              status: 2,
              pageSize: 10,
              currentPage: this.currentPage,
              // categories: "体育运动",
              mechanism_name: query,
            },
          })
          .then((res) => {
            // console.log(res.data);
            this.mechanism_list = res.data.data.rows;
          })
          .catch((rej) => {});
      }
    },
    getSchedule(v) {
      this.$set(this.serviceForm, "list", v);
    },
    clearPlacetable() {
      this.serviceForm.list = [];
      this.venueList = [];
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addPlaceScheduleShow = false;
          this.addCourseVisible = false;
          this.addGudingCourse = false;
          this.week_day = [];
          this.form = {
            week_num: 1,
            months_num: new Date().getDate(),
          };
          this.serviceForm = {
            week_num: 1,
            months_num: new Date().getMonth() + 1,
          };
          this.months_num = new Date();
          done();
        })
        .catch((_) => {});
    },
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const Selects = this.$refs;
          if (Selects.agentSelect) {
            const input =
              Selects.agentSelect.$el.querySelector(".el-input__inner");
            input.removeAttribute("readonly");
          }
        }
      });
    },
  },
};
</script>

<style>
.cal {
  height: 300px;
}
.el-calendar-table .el-calendar-day {
  height: 50px;
}
</style>
