<template>
  <div class="wrap">
    <div class="check">
      <el-checkbox v-model="checkMonth" @change="CheckAllChange"
        >本月</el-checkbox
      >
      <el-checkbox v-model="checkNextMonth" @change="CheckAllNextChange"
        >下月</el-checkbox
      >
    </div>
    <el-calendar v-model="dateList" changeMonth="changeMonth">
      <template v-slot:dateCell="{ data, date }">
        <p
          class="date-p"
          :class="changeList.includes(data.day) ? 'is-selected' : ''"
          @click="change(data, date)"
        >
          {{ data.day.split("-").slice(1).join("-") }}
          {{ changeList.includes(data.day) ? "✔️" : "" }}
        </p>
      </template>
    </el-calendar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dateList: "",
      changeList: [],
      todayTime: "",
      checkMonth: false,
      checkNextMonth: false,
    };
  },
  watch:{
    changeList:{
      deep:true,
      handler(newv){
      this.$emit('change', this.changeList);
      }
    }
  },

  mounted() {
    this.todayTime = new Date().getTime() - 24 * 60 * 60 * 1000;
  },

  methods: {
    clear(){
      this.changeList = []
    },
    CheckAllChange() {
      if (this.checkMonth) {
        let arr = this.currentMonthDays(new Date());
        this.changeList = [...this.changeList, ...arr];
        this.changeList = Array.from(new Set(this.changeList));
      } else {
        let m = new Date().getMonth() + 1;
        let month = m > 10 ? m : "0" + m;
        let arr = [...this.changeList];

        for (let i = arr.length-1; i >=0; i--) {
          if (arr[i].split("-")[1] === month) {
            arr.splice(i, 1);
          }
        }
        this.changeList = arr;
      }
    },
    CheckAllNextChange() {
      let data = new Date()
      if (this.checkNextMonth) {
        let arr = this.currentMonthDays(new Date(data.getFullYear(),data.getMonth()+1,1));
        this.changeList = [...this.changeList, ...arr];
        this.changeList = Array.from(new Set(this.changeList));
      } else {
        let m = new Date().getMonth() + 2;
        let month = m > 10 ? m : "0" + m;
        let arr = [...this.changeList];

        for (let i = arr.length-1; i >=0; i--) {
          if (arr[i].split("-")[1] === month) {
            arr.splice(i, 1);
          }
        }
        this.changeList = arr;
      }
    },
    change(data, date) {
      let c_time = new Date(date).getTime();
      if (c_time < this.todayTime) {
        return;
      }
      let index = this.changeList.indexOf(data.day);
      if (index != -1) {
        this.changeList.splice(index, 1);
      } else {
        this.changeList.push(data.day);
      }
    },
    //
    currentMonthDays(s) {
      let date = new Date(s);
      let currentDay = date.getDate();
      let currentMonth = date.getMonth() + 1;
      if (currentMonth < 10) {
        currentMonth = "0" + currentMonth;
      }
      let currentYear = date.getFullYear();
      let currentMonthDays = new Date(currentYear, currentMonth, 0).getDate();
      let currentMonthArr = [];
      for (let day = currentDay; day <= currentMonthDays; day++) {
        // 年月日(yyyy-MM-dd)
        let dateItem =
          currentYear + "-" + currentMonth + "-" + (day < 10 ? "0" + day : day);
        currentMonthArr.push(dateItem);
      }
      return currentMonthArr;
    },
  },
};
</script>

<style lang="less" scoped>
.is-selected {
  color: #1989fa;
}
.date-p {
  height: 100%;
}
.wrap{
  position: relative;
}
.check{
position: absolute;
left: 120px;
top: 16px;
}
</style>