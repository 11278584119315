<template>
  <div label="每月固定" name="third">
    <div style="padding:0px 50px 10px 0;display: flex;">
      <div style="margin:5px 10px 0 0;width:130px;">每月固定</div>
      <div style="display: flex;flex-wrap: wrap;width:370px;">
        <div v-for="(item, index) in moonEverDay" :key="index" style="margin:6px;">
          <div class="hover-style"
            style="border-radius:5px;font-weight:bold;font-size:14px;cursor: pointer;padding:8px 10px;"
            @click="clickCalendar(item, index)">
            <div>{{ item }}</div>
            <div v-if="collectClickCalendar.indexOf(index) > -1"
              style="width:4px;height:4px;border-radius:50%;background-color:red;position: relative;left:calc(50% - 2px);">
            </div>
            <div v-else style="width:4px;height:4px;"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collectClickCalendar: [], // 收集固定日期选择的日子
      moonEverDay: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31',
      ],
    }
  },
  methods: {
    deleteItemFromArr(item) {
      // eslint-disable-next-line
      Array.prototype.indexOf = function (val) {
        for (var i = 0; i < this.length; i++) {
          if (this[i] === val) {
            return i
          }
        }
        return -1
      }
      // 通过索引删除数组元素
      // eslint-disable-next-line
      Array.prototype.remove = function (val) {
        var index = this.indexOf(val)
        if (index > -1) {
          this.splice(index, 1)
        }
      }
      // 删除数组元素
      this.collectClickCalendar.remove(item)
    },
    clickCalendar(item, index) {
      console.log('item, index:', item, index)
      if (this.collectClickCalendar.findIndex(item) === -1) {
        this.collectClickCalendar.push(item)
      } else if (this.collectClickCalendar.findIndex(item) > -1) {
        this.deleteItemFromArr(item)
      }
      this.$emit('selected', this.collectClickCalendar)
    },
  },
}
</script>

<style lang="less">
.hover-style:hover {
  color: white;
  background-color: blue;
}
</style>