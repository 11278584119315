<template>
  <div class="class-table">
    <div class="table-wrapper">
      <div class="tabel-container">
        <table>
          <thead>
            <tr>
              <th>时间</th>
              <th v-for="(weekNum, weekIndex) in 7" :key="weekIndex">
                {{ "周" + digital2Chinese(weekIndex, "week") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lesson, lessonIndex) in classTableData.lessons" :key="lessonIndex">
              <td>
                <p>{{ lesson.name }}</p>
                <p class="period">
                  {{ lesson.start_time }}-{{ lesson.end_time }}
                </p>
              </td>

              <td v-for="weekNum,index in 7" :key='index'>
                <div v-for="(week, itemIndex) in lesson.week_day.split(',')" :key="itemIndex">
                  <div v-if="week == weekNum">
                    <div>课程：{{ lesson.name || "-" }}</div>
                    <div style="margin-top: 8px">
                      老师：
                      {{ lesson.master_name || "-" }}
                    </div>
                  </div>
                  <!-- <div v-else>-</div> -->
                </div>
                <!-- <div v-else style="margin-top: 8px">-</div> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['mechanism_id', 'added', 'master_set_price_id'],
  watch: {
    master_set_price_id: {
      handler(newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.$nextTick(function () {
            this.getlist()
            this.getlist2()
          })
        }
      },
      deep: true,
      immediate: true,
    },
    added(newV, oldV) {
      if (newV && newV != oldV) {
        this.$nextTick(function () {
          this.getlist()
          this.getlist2()
        })
      }
    },
  },
  computed: {},
  data() {
    return {
      classTableData: {
        lessons: {},
        courses: [
          [
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {},
            {
              course: '生物',
              teacher: '程老师',
            },
            {},
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
          ],
          [
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {},
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
          ],
          [
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {},
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
          ],
          [
            {
              course: '生物',
              teacher: '程老师',
            },
            {},
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {},
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
          ],
          [
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
          ],
          [
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
          ],
          [
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
            {
              course: '生物',
              teacher: '程老师',
            },
          ],
        ],
      },
    }
  },
  created() {
    // /* mock随机数据*/
    // Mock.mock({
    //     lessons: ['08:00-09:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00'],
    //     'courses|7': [['生物', '物理', '化学', '政治', '历史', '英语', '', '语文']]
    // });
  },
  methods: {
    // digital2Chinese1(num, identifier) {
    //   const character = [
    //     "零",
    //     "一",
    //     "二",
    //     "三",
    //     "四",
    //     "五",
    //     "六",
    //     "七",
    //     "八",
    //     "九",
    //     "十",
    //     "十一",
    //     "十二",
    //   ];
    //   return identifier === "week" && (num === 0 || num === 7)
    //     ? "日"
    //     : character[num];
    // },
    //  if_have_data(row,column){
    // 	return this.classTableData[row],
    //   },
    getAnswer(lesson, course) {
      return (
        lesson.start_time == (course ? course.start_time : null) &&
        lesson.end_time == (course ? course.end_time : null)
      )
    },
    digital2Chinese(num, identifier) {
      const character = ['一', '二', '三', '四', '五', '六', '日']
      return identifier === 'week' && character[num]
    },
    getlist() {
      let url = '/user/mechanismSchedule/query',
        params = {
          status: 2,
          is_template: 0,
          mechanism_id: this.mechanism_id,
          master_set_price_id: this.master_set_price_id,
          // class_id:159
        }
      this.$axios.get(url, { params }).then((res) => {
        this.classTableData.lessons = res.data.data
      })
    },
    getlist2() {
      let url = '/user/mechanismSchedule/queryCourseList',
        params = {
          status: 2,
          is_template: 0,
          mechanism_id: this.mechanism_id,
          master_set_price_id: this.master_set_price_id,
        }
      // let tt =[]
      this.$axios.get(url, { params }).then((res) => {
        this.classTableData.courses = res.data.data
        for (let i = 0; i < res.data.data; i++) {}
        // this.classTableData.lessons.forEach((item,index)=>{
        //  this.classTableData.courses.forEach((item1,index1)=>{
        console.log(this.classTableData)

        //  })
        // })
        // console.log(99,tt)
      })
    },
  },
  mounted() {
    // this.getlist();
    // this.getlist2();
  },
}
</script>

<style lang="less" scoped>
.class-table {
  .table-wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .tabel-container {
    margin: 7px;

    table {
      table-layout: fixed;
      width: 100%;

      thead {
        background-color: #67a1ff;
        th {
          color: #fff;
          line-height: 17px;
          font-weight: normal;
        }
      }
      tbody {
        background-color: #eaf2ff;
        td {
          color: #677998;
          line-height: 12px;
        }
      }
      th,
      td {
        width: 60px;
        padding: 12px 2px;
        font-size: 12px;
        text-align: center;
      }

      tr td:first-child {
        color: #333;
        .period {
          font-size: 8px;
        }
      }
    }
  }
}
</style>
